'use client';

import React, { useState, useMemo, useCallback } from 'react';
import { Box, Typography, IconButton, useMediaQuery, useTheme } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import CompactCalendarDay from './compact_calendar_day';

interface SmallCustomCalendarProps {
    selectedDate: Date;
    minDate?: Date;
    maxDate?: Date;
    onDateChange: (date: Date) => void;
    isHomepage?: boolean;
}

const SmallCustomCalendar = ({
    selectedDate,
    minDate,
    maxDate,
    onDateChange,
    isHomepage = false
}: SmallCustomCalendarProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [currentMonth, setCurrentMonth] = useState(() => new Date(selectedDate));
    const weekDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

    // Handle date selection
    const handleDateSelected = useCallback((date: Date) => {
        console.log('Date selected in SmallCustomCalendar:', date);
        if (date) {
            onDateChange(date);
        }
    }, [onDateChange]);

    const calendarDays = useMemo(() => {
        const year = currentMonth.getFullYear();
        const month = currentMonth.getMonth();
        const firstDay = new Date(year, month, 1, 0, 0, 0, 0);
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const startDay = firstDay.getDay();

        return [
            ...Array(startDay).fill(null).map((_, i) => {
                const date = new Date(year, month, -startDay + i + 1);
                date.setHours(0, 0, 0, 0);
                return date;
            }),
            ...Array(daysInMonth).fill(null).map((_, i) => {
                const date = new Date(year, month, i + 1);
                date.setHours(0, 0, 0, 0);
                return date;
            }),
            ...Array(42 - (startDay + daysInMonth)).fill(null).map((_, i) => {
                const date = new Date(year, month + 1, i + 1);
                date.setHours(0, 0, 0, 0);
                return date;
            })
        ];
    }, [currentMonth]);

    const isDateDisabled = useCallback((date: Date) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (!date) return true;

        const compareDate = new Date(date);
        compareDate.setHours(0, 0, 0, 0);

        const isBeforeToday = compareDate.getTime() < today.getTime();
        const isBeforeMinDate = minDate && compareDate.getTime() < minDate.getTime();
        const isAfterMaxDate = maxDate && compareDate.getTime() > maxDate.getTime();

        return isBeforeToday || isBeforeMinDate || isAfterMaxDate;
    }, [minDate, maxDate]);

    const handleMonthChange = useCallback((increment: number) => {
        setCurrentMonth(prevMonth => {
            const newDate = new Date(prevMonth);
            newDate.setMonth(prevMonth.getMonth() + increment);
            newDate.setHours(0, 0, 0, 0);

            // Don't allow going before min date's month
            if (minDate) {
                const minMonth = new Date(minDate.getFullYear(), minDate.getMonth(), 1);
                if (newDate < minMonth) {
                    return prevMonth;
                }
            }

            return newDate;
        });
    }, [minDate]);

    // Prevent clicks on the calendar from bubbling up to parent elements
    const handleCalendarClick = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
    }, []);

    const isLeftArrowDisabled = useMemo(() => {
        if (!minDate) return false;
        const currentFirstDay = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
        const minFirstDay = new Date(minDate.getFullYear(), minDate.getMonth(), 1);
        return currentFirstDay <= minFirstDay;
    }, [currentMonth, minDate]);

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                bgcolor: '#2c2c54',
                p: { xs: isHomepage ? 2 : 1.5, sm: isHomepage ? 2.5 : 2 },
                boxShadow: { xs: 'none', sm: '0px 4px 16px rgba(0, 0, 0, 0.2)' },
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column'
            }}
            onClick={handleCalendarClick}
        >
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: { xs: isHomepage ? 1.5 : 1, sm: isHomepage ? 2 : 1.5 },
                py: 0.5
            }}>
                <IconButton
                    onClick={() => handleMonthChange(-1)}
                    size={isHomepage ? "medium" : "small"}
                    disabled={isLeftArrowDisabled}
                    sx={{
                        color: '#FFFFFF',
                        '&.Mui-disabled': {
                            color: 'rgba(255, 255, 255, 0.3)'
                        },
                        mx: 0.5,
                        p: 0.5
                    }}
                >
                    <ChevronLeft fontSize={isHomepage ? "medium" : "small"} />
                </IconButton>
                <Typography
                    variant="subtitle1"
                    sx={{
                        fontWeight: 600,
                        color: '#FFFFFF',
                        mx: 1,
                        fontFamily: 'Sans',
                        fontSize: { xs: isHomepage ? '1rem' : '0.9rem', sm: isHomepage ? '1.1rem' : '1rem' },
                        letterSpacing: '0.5px'
                    }}
                >
                    {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
                </Typography>
                <IconButton
                    onClick={() => handleMonthChange(1)}
                    size={isHomepage ? "medium" : "small"}
                    sx={{
                        color: '#FFFFFF',
                        mx: 0.5,
                        p: 0.5
                    }}
                >
                    <ChevronRight fontSize={isHomepage ? "medium" : "small"} />
                </IconButton>
            </Box>

            <Box sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(7, 1fr)',
                width: '100%',
                gap: isHomepage ? 0.5 : 0,
                flex: 1,
                '& .MuiTypography-root': {
                    color: '#FFFFFF',
                }
            }}>
                {weekDays.map(day => (
                    <Typography
                        key={day}
                        variant="caption"
                        align="center"
                        sx={{
                            fontWeight: 600,
                            color: '#E0B1F1',
                            mb: 0.5,
                            fontSize: { xs: isHomepage ? '0.75rem' : '0.65rem', sm: isHomepage ? '0.8rem' : '0.7rem' },
                            fontFamily: 'Sans'
                        }}
                    >
                        {day}
                    </Typography>
                ))}

                {calendarDays.map((date, i) => (
                    <Box
                        key={i}
                        sx={{
                            p: isHomepage ? '2px' : '1px',
                        }}
                    >
                        <CompactCalendarDay
                            date={date}
                            isDisabled={isDateDisabled(date)}
                            isSelected={date && selectedDate && date.toDateString() === selectedDate.toDateString()}
                            isCurrentMonth={date && date.getMonth() === currentMonth.getMonth()}
                            onClick={() => date && !isDateDisabled(date) && handleDateSelected(date)}
                            isHomepage={isHomepage}
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default SmallCustomCalendar;
